import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7bd48519 = () => interopDefault(import('../src/pages/cookie-policy.vue' /* webpackChunkName: "pages/cookie-policy" */))
const _39734e1e = () => interopDefault(import('../src/pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _f37f6874 = () => interopDefault(import('../src/pages/manage-booking/index.vue' /* webpackChunkName: "pages/manage-booking/index" */))
const _2eb080ae = () => interopDefault(import('../src/pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _b53dded4 = () => interopDefault(import('../src/pages/receipt.vue' /* webpackChunkName: "pages/receipt" */))
const _7301a88c = () => interopDefault(import('../src/pages/search.vue' /* webpackChunkName: "pages/search" */))
const _62e5a949 = () => interopDefault(import('../src/pages/search-booking/index.vue' /* webpackChunkName: "pages/search-booking/index" */))
const _04f9ad35 = () => interopDefault(import('../src/pages/select-extras/index.vue' /* webpackChunkName: "pages/select-extras/index" */))
const _69f5d7f4 = () => interopDefault(import('../src/pages/terms-and-conditions.vue' /* webpackChunkName: "pages/terms-and-conditions" */))
const _de672958 = () => interopDefault(import('../src/pages/travel-extras.vue' /* webpackChunkName: "pages/travel-extras" */))
const _660c3624 = () => interopDefault(import('../src/pages/unsupported-browser.vue' /* webpackChunkName: "pages/unsupported-browser" */))
const _2ba79c39 = () => interopDefault(import('../src/pages/manage-booking/cancel/index.vue' /* webpackChunkName: "pages/manage-booking/cancel/index" */))
const _60154076 = () => interopDefault(import('../src/pages/manage-booking/modify.vue' /* webpackChunkName: "pages/manage-booking/modify" */))
const _5f738b2c = () => interopDefault(import('../src/pages/manage-booking/receipt.vue' /* webpackChunkName: "pages/manage-booking/receipt" */))
const _05beb6fa = () => interopDefault(import('../src/pages/manage-booking/update-details.vue' /* webpackChunkName: "pages/manage-booking/update-details" */))
const _71daa3d5 = () => interopDefault(import('../src/pages/select-extras/fasttrack/index.vue' /* webpackChunkName: "pages/select-extras/fasttrack/index" */))
const _3f96ae1f = () => interopDefault(import('../src/pages/select-extras/lounges/index.vue' /* webpackChunkName: "pages/select-extras/lounges/index" */))
const _6c5db5d6 = () => interopDefault(import('../src/pages/select-extras/product/index.vue' /* webpackChunkName: "pages/select-extras/product/index" */))
const _48488adf = () => interopDefault(import('../src/pages/manage-booking/cancel/receipt.vue' /* webpackChunkName: "pages/manage-booking/cancel/receipt" */))
const _c477b8a0 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/cookie-policy",
    component: _7bd48519,
    name: "cookie-policy"
  }, {
    path: "/maintenance",
    component: _39734e1e,
    name: "maintenance"
  }, {
    path: "/manage-booking",
    component: _f37f6874,
    name: "manage-booking"
  }, {
    path: "/privacy-policy",
    component: _2eb080ae,
    name: "privacy-policy"
  }, {
    path: "/receipt",
    component: _b53dded4,
    name: "receipt"
  }, {
    path: "/search",
    component: _7301a88c,
    name: "search"
  }, {
    path: "/search-booking",
    component: _62e5a949,
    name: "search-booking"
  }, {
    path: "/select-extras",
    component: _04f9ad35,
    name: "select-extras"
  }, {
    path: "/terms-and-conditions",
    component: _69f5d7f4,
    name: "terms-and-conditions"
  }, {
    path: "/travel-extras",
    component: _de672958,
    name: "travel-extras"
  }, {
    path: "/unsupported-browser",
    component: _660c3624,
    name: "unsupported-browser"
  }, {
    path: "/manage-booking/cancel",
    component: _2ba79c39,
    name: "manage-booking-cancel"
  }, {
    path: "/manage-booking/modify",
    component: _60154076,
    name: "manage-booking-modify"
  }, {
    path: "/manage-booking/receipt",
    component: _5f738b2c,
    name: "manage-booking-receipt"
  }, {
    path: "/manage-booking/update-details",
    component: _05beb6fa,
    name: "manage-booking-update-details"
  }, {
    path: "/select-extras/fasttrack",
    component: _71daa3d5,
    name: "select-extras-fasttrack"
  }, {
    path: "/select-extras/lounges",
    component: _3f96ae1f,
    name: "select-extras-lounges"
  }, {
    path: "/select-extras/product",
    component: _6c5db5d6,
    name: "select-extras-product"
  }, {
    path: "/manage-booking/cancel/receipt",
    component: _48488adf,
    name: "manage-booking-cancel-receipt"
  }, {
    path: "/",
    component: _c477b8a0,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
